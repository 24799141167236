import * as React from 'react';

import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import Typo from '@mui/material/Typography'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Fade from '@mui/material/Fade';

// import Nav from'hendrixmaterial/nav'
// import Button from'hendrixmaterial/button'
// import Box from'hendrixmaterial/box'
// import siteConfig from'siteConfig'

import{titles}from'./titles'


export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const makeItem=(item)=><MenuItem onClick={handleClose}>{item}</MenuItem>


  const _btn=<IconButton
    color="primary"
    children={<MenuIcon/>}
    onClick={handleClick}
  />


  const getItemText=(children)=>{
    return <Typo component='span' variant='button' sx={{color:theme=>theme.palette.primary.light}}>{children}</Typo>
  }

  const menuItems=titles.slice(1).map((o,i)=><MenuItem
    key={`${i}-menuItem`}
    onClick={o.onClick}
    >
      {getItemText(o.children)}
    </MenuItem>
  )

  const _menu=<Menu
    anchorEl={anchorEl}
    open={open}
    TransitionComponent={Fade}
    onClick={handleClose}
    onClose={handleClose}
    children={menuItems}
  />

  return (
    <>
      {_btn}
      {_menu}
    </>
  );
}
