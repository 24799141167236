import * as React from 'react';
import PropTypes from 'prop-types'
import{ThemeProvider}from '@mui/material/styles';

import{theme}from'./theme'
// import Style from'./style'

import'./styles.css'

export default function ProviderTheme({children}){
  const PROVIDER=<ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
  return PROVIDER
}

ProviderTheme.propTypes={
  children: PropTypes.node
}
