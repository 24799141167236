import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import Nav from './nav'
import Seo from'./seo'

import Box from'hendrixmaterial/box'
import classroomUrl from'../images/classroom.jpg'

import'./styles.css'


export const Background=()=><div
  style={{
    height:'100vh',width:'100vw',backgroundImage:`url(${classroomUrl})`,
    backgroundPosition:'right',backgroundSize:'cover',
    position:'absolute',top:'0px',zIndex:'-1000',
    filter:'blur(20px)'
  }}
/>


const parseLocation=pathname=>pathname.split('/')[1]


export const getTitle     =pathname=>{
  const parsed=parseLocation(pathname)
  if(parsed.length===0){return 'Home'}
  else                 {return parsed.charAt(0).toUpperCase()+parsed.slice(1)}
}


export default function Layout({children,...rest}){
  const PAGE   =getTitle(rest?.location?.pathname)
  const NAV    =<Nav {...rest}/>
  const CSS    =<CssBaseline/>
  const SEO    =<Seo page={PAGE}/>
  const BKG    =<Background/>
  const WRAPPER=<Box
    sx={{height:'100%',width:'100%'}}
    children={<>
      {children}
      {BKG}
    </>}
  />

  return<>
    {CSS}
    {NAV}
    {SEO}
    {WRAPPER}
  </>
}
