import * as React from 'react';
import{navigate}from'gatsby'

import HomeIcon from'hendrixicons/home'
import { withExternalLink } from 'svgs/external-link';


const withLink={
  internal:(link:string)=>navigate(link==='/'?link:`/${link}/`),
  external:(link:string)=>window.open(link)}

export const titles=[
  {
    children:<HomeIcon/>,
    link:'/',
    onClick:()=>withLink.internal('/')
  },
  {
    children:withExternalLink('buy'),
    link:'https://www.amazon.com/dp/B09Y4XN7L9/',
    onClick:()=>withLink.external('https://www.amazon.com/dp/B09Y4XN7L9/')
  },
  {
    children:'activities',
    onClick:()=>withLink.internal('activities')
  },
  {
    children:'curriculum',
    onClick:()=>withLink.internal('curriculum')
  },
  {
    children:'games',
    onClick:()=>withLink.internal('games')
  },
  {
    children:"what's next",
    link:'whats-next',
    onClick:()=>withLink.internal('whats-next')
  },
]
