exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-activities-jsx": () => import("./../../../src/pages/activities.jsx" /* webpackChunkName: "component---src-pages-activities-jsx" */),
  "component---src-pages-axios-jsx": () => import("./../../../src/pages/axios.jsx" /* webpackChunkName: "component---src-pages-axios-jsx" */),
  "component---src-pages-curriculum-jsx": () => import("./../../../src/pages/curriculum.jsx" /* webpackChunkName: "component---src-pages-curriculum-jsx" */),
  "component---src-pages-games-jsx": () => import("./../../../src/pages/games.jsx" /* webpackChunkName: "component---src-pages-games-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mui-jsx": () => import("./../../../src/pages/mui.jsx" /* webpackChunkName: "component---src-pages-mui-jsx" */),
  "component---src-pages-page-2-jsx": () => import("./../../../src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */),
  "component---src-pages-starter-jsx": () => import("./../../../src/pages/starter.jsx" /* webpackChunkName: "component---src-pages-starter-jsx" */),
  "component---src-pages-using-ssr-jsx": () => import("./../../../src/pages/using-ssr.jsx" /* webpackChunkName: "component---src-pages-using-ssr-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-whats-next-jsx": () => import("./../../../src/pages/whats-next.jsx" /* webpackChunkName: "component---src-pages-whats-next-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

