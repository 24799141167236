import * as React from "react";


interface Size {
  width: number | undefined;
  height: number | undefined;
}

export function useWindowSize(log=false): Size {
  // Initialize state with undefined width/height so server and client renders match Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  React.useEffect(()=>{
    if(log){console.log('windowSize',windowSize)}
  },[windowSize,log])

  return windowSize;
}
export default useWindowSize


export function ExampleUseWindowSize(){
  const windowSize=useWindowSize()
  return (
    <div style={{color:'pink',margin:'auto',width:'fit-content'}}>
      window size: {JSON.stringify(windowSize)}
    </div>
  );
}
